import moment from "moment";
import { useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useAsyncCallback } from "react-use-async-callback";
import { Alert, Spinner } from "reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync";
import { useCancelChildAbsenceMutation } from "../../../../api/main/childAbsences/useCancelChildAbsenceMutation";
import { useAbsencesTabViewModel } from "../../../../api/main/childAbsences/viewModels/useAbsencesTabViewModel";
import { CancelChildAbsenceResult } from "../../../../api/main/generated/graphql";
import { Child } from "../../../../api/main/models/Child";
import { AlertOnErrors } from "../../../../shared/alertOnErrors";
import { getScheduledClassSummary } from "../../../scheduledClass/getScheduledClassSummary";
import { CardsOrTable } from "../../../shared/cardsOrTable/CardsOrTable";
import { NoResultsFound } from "../../../shared/noResultsFound/NoResultsFound";

export interface AbsencesTabProps {
    model: Child | null | undefined,
}

export const AbsencesTab = (props: AbsencesTabProps) => {
    const {
        model
    } = props;
    const { t } = useTranslation();

    // Load the data
    const {
        data: {
            items: _items,
            childAbsenceTypes,
            scheduledClasses,
            classLocations,
            classStages,
            classSubStages
        }, isLoading: _isLoading, errors: loadErrors, refresh
    } = useAbsencesTabViewModel(model?.id);
    const isLoading = _isLoading;

    const items = useMemo(() => _items?.map(item => {
        const childAbsenceType = childAbsenceTypes?.find(it => it.id === item.childAbsenceTypeId);
        const scheduledClass = scheduledClasses?.find(it => it.id === item.scheduledClassId);
        const classLocation = classLocations?.find(it => it.id === scheduledClass?.classLocationId);
        const classStage = classStages?.find(it => it.id === scheduledClass?.currentClassStageId);
        const classSubStage = classSubStages?.find(it => it.id === scheduledClass?.currentClassSubStageId);


        return {
            ...item,
            childAbsenceType,
            scheduledClass,
            classLocation,
            classStage,
            classSubStage
        };
    }), [_items, childAbsenceTypes, scheduledClasses, classLocations, classStages, classSubStages]);

    const allItems = useMemo(() => {
        if (!items) return [];

        // Return items with a future endDate
        return items.filter(item => {
            return item.endDate && new Date(item.endDate) > new Date();
        });

    }, [items]);

    const [messageType, setMessageType] = useState<boolean | undefined | null>(false);
    const [message, setMessage] = useState<string | undefined | null>(undefined);

    // Cancel a child absence.
    const [cancelChildAbsence, { errors: cancelChildAbsenceErrors }] = useCancelChildAbsenceMutation();
    const [cancel, { isExecuting: isCancelling, errors: cancelErrors }] = useAsyncCallback(async (id: string) => {
        if (!id) return;

        const result: CancelChildAbsenceResult = await cancelChildAbsence(id);

        if (result) {
            setMessageType(result.success);
            setMessage(result.message);

            // Hide the message after a few seconds.
            setTimeout(() => setMessage(undefined), 5000);
        } else {
            //console.log('No result');
        }

        refresh();
    }, [cancelChildAbsence]);

    // Render the UI
    //
    return (
        <>
            <AlertOnErrors errors={[
                loadErrors,
                cancelChildAbsenceErrors, cancelErrors
            ]} />

            <div style={{ width: '100%' }}>
                <h2 style={{ margin: '0 auto', fontSize: '2rem', fontFamily: 'museosansrounded', fontWeight: '600', textAlign: 'center', marginBottom: '1rem' }}>{t('editChild.absencesTab.heading', 'Absences')}</h2>
            </div>

            <ConditionalFragment showIf={!!message}>
                <Alert color={messageType === true ? 'success' : 'danger'}>
                    {message}
                </Alert>
            </ConditionalFragment>

            <ConditionalFragment showIf={!isLoading && !!allItems?.length}>
                <CardsOrTable
                    viewMode={"table"}
                    items={allItems}
                    tableHeadings={[
                        t('editChild.absencesTab.absenceDate.heading', 'Absence date'),
                        t('editChild.absencesTab.class.heading', 'Class'),
                        t('editChild.absencesTab.absenceType.heading', 'Absence type'),
                    ]}
                    columns={[
                        // Absence date
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(item.startDate) });
                        },

                        // Class
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return getScheduledClassSummary(item.scheduledClass, { classLocation: item.classLocation, classStage: item.classStage, classSubStage: item.classSubStage });
                        },

                        // Absence type
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return item.childAbsenceType?.name;
                        },

                        // Cancel
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return (
                                <ConditionalFragment showIf={!isLoading}>
                                    <ButtonAsync color="danger" outline isExecuting={isCancelling} onClick={() => cancel(item.id)}
                                        executingChildren={<><Spinner size="sm" /> {t('common.cancelling', 'Cancelling...')}</>}>
                                        {t('common.cancel', 'Cancel')}
                                    </ButtonAsync>
                                </ConditionalFragment>
                            );
                        },
                    ]}
                />
            </ConditionalFragment>

            <ConditionalFragment showIf={!isLoading && !items?.length}>
                <NoResultsFound />
            </ConditionalFragment>
        </>
    );
};