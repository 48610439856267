import { useLazyQuery } from "@apollo/client";
import gql from "graphql-tag";
import { Guid } from "guid-string";
import React from "react";
import { mainApiConfig } from "../../../../configure/mainApiConfig";
import { AsyncLoadOptions, AsyncLoadResult } from "../../../../shared/abstractStore";
import { AbsencesTabViewModelQuery, AbsencesTabViewModelQueryVariables } from '../../generated/graphql';
import { childAbsenceFieldsFragment } from "../../models/ChildAbsence";
import { childAbsenceTypeFieldsFragment } from "../../models/ChildAbsenceType";
import { classLocationFieldsFragment } from "../../models/ClassLocation";
import { classStageFieldsFragment } from "../../models/ClassStage";
import { classSubStageFieldsFragment } from "../../models/ClassSubStage";
import { scheduledClassFieldsFragment } from "../../models/ScheduledClass";

export function useAbsencesTabViewModel(childId: string | null | undefined, options: AsyncLoadOptions = {}): AsyncLoadResult<AbsencesTabViewModelQuery> {
    // Query data we need from the api.
    const [load, { data, loading, error, refetch }] = useLazyQuery<AbsencesTabViewModelQuery, AbsencesTabViewModelQueryVariables>(
        gql`
        query absencesTabViewModelQuery ($childId: ID!) {
            items: childAbsences (childId: $childId) {
                ...childAbsenceFields
            }

            childAbsenceTypes {
                ...childAbsenceTypeFields
            }

            scheduledClasses {
                ...scheduledClassFields
            }

            classLocations {
                ...classLocationFields
            }

            classStages {
                ...classStageFields
            }

            classSubStages {
                ...classSubStageFields
            }
        }

        ${childAbsenceFieldsFragment}
        ${childAbsenceTypeFieldsFragment}
        ${scheduledClassFieldsFragment}
        ${classLocationFieldsFragment}
        ${classStageFieldsFragment}
        ${classSubStageFieldsFragment}
        `,
        {
            variables: {
                childId: childId ?? Guid.empty
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
    }), [data, refresh, loading, error]);
};