import * as React from 'react';
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { AsyncLoadResult, AsyncLoadOptions } from '../../../../shared/abstractStore';
import { mainApiConfig } from '../../../../configure/mainApiConfig';
import { courtesyClassCreditFieldsFragment } from '../../models/CourtesyClassCredit';
import { CourtesyClassCreditsForMembersChildViewModelQuery, CourtesyClassCreditsForMembersChildViewModelQueryVariables } from '../../generated/graphql';
import { Guid } from 'guid-string';
import { childAttendanceFieldsFragment } from '../../models/ChildAttendance';
import { scheduledClassFieldsFragment } from '../../models/ScheduledClass';
import { scheduledClassStaffFieldsFragment } from '../../models/ScheduledClassStaff';
import { classLocationFieldsFragment } from '../../models/ClassLocation';
import { classSubStageFieldsFragment } from '../../models/ClassSubStage';
import { classTypeFieldsFragment } from '../../models/ClassType';
import { classStageFieldsFragment } from '../../models/ClassStage';

/**
 * Get a list of CourtesyClassCredits from the api.
 * @param childId
 * @param options
 * @returns
 */
export function useCourtesyClassCreditsForMembersChildViewModel(childId: string | undefined | null, termId: string | undefined | null, options: AsyncLoadOptions = {}): AsyncLoadResult<CourtesyClassCreditsForMembersChildViewModelQuery> {
    // Query data we need from the api.
    const [load, { data, loading, error, refetch }] = useLazyQuery<CourtesyClassCreditsForMembersChildViewModelQuery, CourtesyClassCreditsForMembersChildViewModelQueryVariables>(
        gql`
        query courtesyClassCreditsForMembersChildViewModelQuery ($childId: ID!, $termId: ID!) {
            items: courtesyClassCredits (childId: $childId, termId: $termId) {
                ...courtesyClassCreditFields
            }

            childAttendances (childId: $childId) {
                ...childAttendanceFields

                scheduledClass {
                    ...scheduledClassFields

                    scheduledClassStaffs {
                        ...scheduledClassStaffFields
                    }

                }
            }

            classLocations {
                ...classLocationFields
            }

            profiles {
                 id
                userId
                firstName
                lastName
                archived
                primaryEmail
                secondaryEmail
                primaryPhone
                secondaryPhone
                addressLine1
                addressLine2
                city
                county
                postcode
                country
                genderId
                isStaff
                isMember
                
                user {
                  id
                  email
                }
            }

            classStages {
                ...classStageFields
            }

            classSubStages {
                ...classSubStageFields
            }

            classTypes {
                ...classTypeFields
            }
        }

        ${courtesyClassCreditFieldsFragment}
        ${childAttendanceFieldsFragment}
        ${scheduledClassFieldsFragment}
        ${scheduledClassStaffFieldsFragment}
        ${classLocationFieldsFragment}
        ${classStageFieldsFragment}
        ${classSubStageFieldsFragment}
        ${classTypeFieldsFragment}
        `,
        {
            variables: {
                childId: childId ?? Guid.empty,
                termId: termId ?? Guid.empty,
            },
            fetchPolicy: options.fetchPolicy ?? mainApiConfig.defaultFetchPolicy,
            pollInterval: options.pollInterval,
            onCompleted: (data) => {
                if (options.onCompleted) {
                    options.onCompleted(data);
                }
            }
        }
    );

    // Run the query (unless we are being lazy).
    React.useEffect(() => {
        if (!options.lazy) {
            load();
        }
    }, [options.lazy, load]);

    // Return the results.
    const refresh = React.useCallback(async () => { !refetch ? await load() : await refetch(); }, [refetch, load]);
    return React.useMemo(() => ({
        data: data ?? ({} as any),
        refresh: refresh,
        isLoading: loading,
        errors: error,
    }), [data, refresh, loading, error,]);
}