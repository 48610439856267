import { useMemo } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { Child } from "../../../../api/main/models/Child";
import { ChildAttendance } from "../../../../api/main/models/ChildAttendance";
import { ClassLocation } from "../../../../api/main/models/ClassLocation";
import { RoleInClass } from "../../../../api/main/models/constants/RoleInClass";
import { Profile } from "../../../../api/main/models/Profile";
import { ScheduledClass } from "../../../../api/main/models/ScheduledClass";
import { ScheduledClassChild } from "../../../../api/main/models/ScheduledClassChild";
import { useConsumeChildClassesTabViewModel } from "../../../../api/main/scheduledClassChildren/viewModels/useConsumeChildClassesTabViewModel";
import { useCurrentTerm } from "../../../../api/main/terms/useCurrentTerm";
import { useNextTerm } from "../../../../api/main/terms/useNextTerm";
import { AlertOnErrors } from "../../../../shared/alertOnErrors";
import { LoadingIndicator } from "../../../shared/loadingIndicator/LoadingIndicator";
import { NoResultsFound } from "../../../shared/noResultsFound/NoResultsFound";
import { AddClassComponent } from "./AddClassComponent";
import { ClassesComponent } from "./classesComponent";
import "./classesTab.scss";

export interface ScheduledClassWithLessonDatesForCurrentTerm extends ScheduledClass {
    lessonDatesForCurrentTerm?: Array<string> | undefined;
};
export interface ScheduledClassChildWithLocationAndStaff extends ScheduledClassChild {
    additionalTeacherProfiles: Array<Profile> | undefined,
    cubCoordinatorProfiles: Array<Profile> | undefined,
    location: ClassLocation | undefined,
    primaryTeacherProfile: Profile | undefined,
    scheduledClass: ScheduledClassWithLessonDatesForCurrentTerm | undefined,
    numberOfLessons: number | undefined;
};

export interface ChildAttendanceWithLocationAndStaff extends ChildAttendance {
    additionalTeacherProfiles: Array<Profile> | undefined,
    cubCoordinatorProfiles: Array<Profile> | undefined,
    location: ClassLocation | undefined,
    primaryTeacherProfile: Profile | undefined,
    scheduledClass: ScheduledClassWithLessonDatesForCurrentTerm | undefined,
    numberOfLessons: number | undefined;
};

export interface ClassesTabProps {
    model: Child | null | undefined,
}

/**
 * Component under MembersChildrenList that lets the user view their Active Classes.
 */
export const ClassesTab = (props: ClassesTabProps) => {
    const { model } = props;

    // Load the data
    const {
        data: {
            items: _allItems,
            classLocations,
            classTypes,
            profiles,
            classSubStages,
            classStages,
        }, errors: loadErrors, isLoading: _isLoading, refresh
    } = useConsumeChildClassesTabViewModel(model?.id);

    const {
        data: {
            model: currentTerm
        }, isLoading: currentTermIsLoading
    } = useCurrentTerm();

    const {
        data: {
            model: nextTerm
        }, isLoading: nextTermIsLoading
    } = useNextTerm();
    const isLoading = _isLoading || currentTermIsLoading || nextTermIsLoading;

    const termToShow = useMemo(() => {
        if (!currentTerm && !nextTerm) return null;

        if (currentTerm) return currentTerm;

        return nextTerm;
    }, [currentTerm, nextTerm]);

    // Combine the data
    const allItems = useMemo(() => _allItems?.filter(it => it.scheduledClass?.termId === termToShow?.id)?.map(item => {
        const location = classLocations?.find(it => it.id === item.scheduledClass.classLocationId);

        // Primary teacher
        const primaryTeacher = item.scheduledClass.scheduledClassStaffs.find(it => it.roleInClass === RoleInClass.PrimaryTeacher && it.scheduledClassId === item.scheduledClassId);
        const primaryTeacherProfile = profiles?.find(it => it.id === primaryTeacher?.staffId);

        // Additional teachers
        const additionalTeachers = item.scheduledClass.scheduledClassStaffs.filter(it => it.roleInClass === RoleInClass.AdditionalTeacher && it.scheduledClassId === item.scheduledClassId);
        const additionalTeacherProfiles = additionalTeachers?.map(additionalTeacher => {
            const profile = profiles?.find(it => it.id === additionalTeacher.staffId);

            return {
                ...(profile ?? {}),

                profile,
            };
        });

        // Cub coordinators
        const cubCoordinators = item.scheduledClass.scheduledClassStaffs.filter(it => it.roleInClass === RoleInClass.Coordinator && it.scheduledClassId === item.scheduledClassId);
        const cubCoordinatorProfiles = cubCoordinators?.map(cubCoordinator => {
            const profile = profiles?.find(it => it.id === cubCoordinator.staffId);

            return {
                ...(profile ?? {}),

                profile,
            };
        });

        // NumberOfLessons for CurrentClassSubStage
        const numberOfLessons = classSubStages?.find(it => it.id === item.scheduledClass.currentClassSubStageId)?.numberOfLessons ?? 0;

        const lessonDatesForCurrentTerm = item.scheduledClass.lessonDatesForCurrentTerm;

        // Return the combined data
        return {
            ...item,
            location,
            primaryTeacherProfile,
            additionalTeacherProfiles,
            cubCoordinatorProfiles,
            numberOfLessons,
            lessonDatesForCurrentTerm
        } as ScheduledClassChildWithLocationAndStaff;
    }), [classLocations, _allItems, profiles, classSubStages, termToShow]);

    // Render the UI 
    //
    return (
        <div className="classes-tab">
            <AlertOnErrors errors={[
                loadErrors,
            ]} />

            <div className="classes-tab-class-type">
                <h2 style={{ margin: '0 auto', fontSize: '2rem', fontFamily: 'museosansrounded', fontWeight: '600' }}>My Classes</h2>

                <ConditionalFragment showIf={!!allItems?.length}>
                    {
                        classTypes?.map(classType => {
                            const myClasses = allItems?.filter(it => it.scheduledClass?.classTypeId === classType.id);

                            return (
                                <div key={classType.id}>
                                    <h3 className="classes-tab-class-type-header">
                                        {classType.name} classes
                                    </h3>

                                    {/* Show all the classes the child is registered in for this class type. */}

                                    <div className="classes-tab-class-type-class-classes">
                                        {
                                            myClasses?.map(item => (
                                                <ClassesComponent
                                                    model={item as ScheduledClassChildWithLocationAndStaff}
                                                    key={item.id}
                                                    refresh={refresh}
                                                    classType={classType.name}
                                                    classStages={classStages}
                                                    classSubStages={classSubStages}
                                                />
                                            ))
                                        }

                                        {/* Have a large option to find another class */}
                                        <AddClassComponent
                                            child={model}
                                            classType={classType}
                                            isFirstClass={!myClasses?.length}
                                        />
                                    </div>
                                </div>
                            );
                        })
                    }
                </ConditionalFragment>
            </div>

            <ConditionalFragment showIf={!isLoading && !allItems?.length}>
                <NoResultsFound />
            </ConditionalFragment>

            <ConditionalFragment showIf={isLoading}>
                <LoadingIndicator fullWidth />
            </ConditionalFragment>
        </div>
    );
};