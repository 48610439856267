import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Badge, Button, Card, CardBody, CardHeader, Col, Input, Label, ListGroup, ListGroupItem, ModalBody, ModalFooter, ModalHeader, Row, Table } from "reactstrap";
import { useToggleState, useToggleStateArray } from "use-toggle-state";
import { useClassNavigatorViewModel } from "../../api/main/classNavigator/viewModels/useClassNavigatorViewModel";
import { Child } from "../../api/main/models/Child";
import { dayOfWeekDisplayName } from "../../api/main/models/constants/DayOfWeek";
import { Profile } from "../../api/main/models/Profile";
import { ScheduledClass } from "../../api/main/models/ScheduledClass";
import { StaffNote } from "../../api/main/models/StaffNote";
import { useCurrentTerm } from "../../api/main/terms/useCurrentTerm";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { HtmlDisplay } from "../../shared/htmlEditor";
import { useLocalStorage } from "../../shared/useLocalStorage";
import { useReplaceSearchParamsEffect, useSearchParams } from "../../shared/useURLSearchParams";
import { iconColors } from "../../styles/iconColors";
import { ClassScheduleFilterState } from "../scheduledClass/schedule/ClassSchedule";
import { Banner } from "../shared/banner/Banner";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../shared/mainContainer/MainContainer";
import { SearchInput } from "../shared/searchInput/SearchInput";
import { StickyToolbar } from "../shared/stickyToolbar/StickyToolbar";
import { StyledModal } from "../shared/styledModal/StyledModal";
import { TermSelector } from "../shared/termSelector/TermSelector";
import { TooltipWrapper } from "../shared/Utilities/TooltipWrapper";
import "./classNavigator.scss";

export interface MemberProfileWithStaffNotes extends Profile {
    notes: Array<StaffNote>;
};

export interface CubWithMemberProfileAndMemberNotes extends Child {
    memberProfile: Profile | undefined | null,
    memberNotes: Array<StaffNote>;
}

/**
 * Navigation of ScheduledClasses - Provides a tree like structure of the classes and cubs
 */
export const ClassNavigator = () => {
    const { t } = useTranslation();

    // Load the current term
    const {
        data: {
            model: currentTerm
        }, isLoading: isLoadingCurrentTerm, errors: currentTermLoadErrors
    } = useCurrentTerm();

    // Terms filter - Allow filtering (persisted to local storage between sessions).
    const [filterState, setFilterState] = useLocalStorage<ClassScheduleFilterState>('classSchedule.filterState', {});
    const changeFilterState = useCallback((changes: Partial<ClassScheduleFilterState>) => setFilterState(prevState => ({
        ...prevState,
        ...changes,
    })), [setFilterState]);

    // Load the data
    const {
        data: {
            scheduledClasses: _allItems,
            classLocations,
            classTypes,
            classStages,
            classSubStages,
            scheduledClassChildren,
            profiles,
            staffNotes,
            childAttendances,
            classLocationClassTypes,
            terms,
            termHolidays
        }, isLoading: isLoadingData, errors: loadErrors, refresh: refreshContent
    } = useClassNavigatorViewModel(filterState.termId);
    const isLoading = isLoadingData || isLoadingCurrentTerm;

    // Filter the items that can be filtered by other filter choices.
    const classStagesForFilter = useMemo(() => classStages?.filter(item => item.classTypeId === filterState.classTypeId), [classStages, filterState]);
    const classSubStagesForFilter = useMemo(() => classSubStages?.filter(item => item.classStageId === filterState.classStageId), [classSubStages, filterState]);

    // Utility function that will return a key for a group of classes as we show them in the UI.
    function getClassGroupKey(item: ScheduledClass) {
        return `${item.classTypeId}:${item.classLocationId}:${item.dayOfWeek}`;
    }

    // Combine all the loaded data so each item includes its related data
    const allItems = useMemo(() => _allItems?.map(item => {
        const classLocation = classLocations?.find(it => it.id === item.classLocationId);
        const classType = classTypes?.find(it => it.id === item.classTypeId);
        const classStage = classStages?.find(it => it.id === item.currentClassStageId);
        const classSubStage = classSubStages?.find(it => it.id === item.currentClassSubStageId);
        const cubs = scheduledClassChildren?.filter(it => it.scheduledClassId === item.id && !it.isOnWaitingList).map(scheduledClassChild => {
            const memberProfile = profiles?.find(it => it.id === scheduledClassChild.child.memberId);
            const memberNotes = staffNotes?.filter(it => it.targetId === memberProfile?.id && it.targetType === 'Member');

            return {
                ...scheduledClassChild.child,
                memberProfile,
                memberNotes
            };

        });
        const waitingListCubs = scheduledClassChildren?.filter(it => it.scheduledClassId === item.id && it.isOnWaitingList);

        const cubAttendances = childAttendances?.filter(it => it.scheduledClassId === item.id);

        return {
            ...item,
            classLocation,
            classType,
            classStage,
            classSubStage,
            cubs,
            cubAttendances,
            waitingListCubs,

            cubSearchResults: cubs,

            _groupKey: getClassGroupKey(item),
        };
    }), [_allItems, classLocations, classTypes, classStages, classSubStages, scheduledClassChildren, childAttendances, profiles, staffNotes]);

    // Search.
    const { classSearch: classSearchParam, cubSearch: cubSearchParam } = useSearchParams();
    const [classSearch, setClassSearch] = useState<string>(classSearchParam ?? '');
    const [cubSearch, setCubSearch] = useState<string>(cubSearchParam ?? '');

    // Keep the URL up to date with the search text.
    useReplaceSearchParamsEffect({ classSearch: classSearch, cubSearch: cubSearch });

    // Filter the items.
    const items = useMemo(() => {
        let ret = (allItems ?? []);

        // Filter by classType (Activity)
        if (filterState.classTypeId) {
            ret = ret.filter(it => it.classTypeId === filterState.classTypeId);
        }

        if (filterState.classStageId) {
            ret = ret?.filter(item => item.currentClassStageId === filterState.classStageId);
        }

        if (filterState.classSubStageId) {
            ret = ret?.filter(item => item.currentClassSubStageId === filterState.classSubStageId);
        }

        if (filterState.classLocationId) {
            ret = ret?.filter(item => item.classLocationId === filterState.classLocationId);
        }

        // Filtering -> Class Search
        let lowerClassSeach = classSearch.toLocaleLowerCase();
        if (lowerClassSeach) {
            ret = ret.filter(item =>
                (item.classLocation?.name ?? '').toLocaleLowerCase().indexOf(lowerClassSeach) >= 0
                || dayOfWeekDisplayName(item.dayOfWeek, t).toLocaleLowerCase().indexOf(lowerClassSeach) >= 0
                || (item.classStage?.name ?? '').toLocaleLowerCase().indexOf(lowerClassSeach) >= 0
                || (item.classSubStage?.name ?? '').toLocaleLowerCase().indexOf(lowerClassSeach) >= 0
            );
        }

        // Filtering -> Cub Search
        let lowerCubSearch = cubSearch.toLocaleLowerCase();
        if (lowerCubSearch) {
            ret = ret.map(item => ({
                ...item,

                cubSearchResults: item.cubs?.filter(cub =>
                    cub.firstName.toLocaleLowerCase().indexOf(lowerCubSearch) >= 0
                    || cub.lastName.toLocaleLowerCase().indexOf(lowerCubSearch) >= 0
                    || (cub.memberProfile?.firstName ?? '').toLocaleLowerCase().indexOf(lowerCubSearch) >= 0
                    || (cub.memberProfile?.lastName ?? '').toLocaleLowerCase().indexOf(lowerCubSearch) >= 0
                ),
            }));

            ret = ret.filter(item => !!item.cubSearchResults.length);
        }

        // Sorting
        // Sort by classType.name, then by classLocation.name, then by dayOfWeek
        ret.sort((a, b) => {
            if ((a.classType?.name ?? '') === (b.classType?.name ?? '')) {
                if ((a.classLocation?.name ?? '') === (b.classLocation?.name ?? '')) {
                    // When sorting by day of week we want Sunday to be last.
                    const dayOfWeekA = a.dayOfWeek === 0 ? 7 : a.dayOfWeek;
                    const dayOfWeekB = b.dayOfWeek === 0 ? 7 : b.dayOfWeek;
                    if (dayOfWeekA === dayOfWeekB) {
                        return 0;
                    } else if (dayOfWeekA < dayOfWeekB) {
                        return -1;
                    } else {
                        return 1;
                    }
                } else if ((a.classLocation?.name ?? '') < (b.classLocation?.name ?? '')) {
                    return -1;
                } else {
                    return 1;
                }
            } else if ((a.classType?.name ?? '') > (b.classType?.name ?? '')) {
                return -1;
            } else {
                return 1;
            }
        });

        return ret;
    }, [allItems, filterState, classSearch, t, cubSearch,]);

    // Showing and hiding UI elements (persisted to local storage between sessions).
    const [isFiltersVisible, _setIsFiltersVisible] = useLocalStorage('classSchedule.isFiltersVisible', false);
    const toggleFiltersVisible = useCallback(() => _setIsFiltersVisible(prevState => !prevState), [_setIsFiltersVisible]);
    const resetFilters = useCallback(() => setFilterState({ termId: currentTerm?.id }), [setFilterState, currentTerm?.id]);

    // Filtering the ClassLocations -> If viewing a specific class type, only show the locations that have that class type.
    const filteredClassLocations = useMemo(() => {
        if (!classLocations || !classLocationClassTypes) {
            return;
        }

        // If we aren't filtering by class type, return all class locations.
        if (!filterState?.classTypeId) {
            return classLocations;
        }

        // Otherwise, return only the class locations that have the class type.
        return classLocations.filter(classLocation => {
            const matchingClassTypes = classLocationClassTypes.filter(classLocationClassType => {
                return classLocationClassType.classLocationId === classLocation.id && classLocationClassType.classTypeId === filterState.classTypeId;
            });

            return matchingClassTypes.length > 0;
        });
    }, [filterState, classLocations, classLocationClassTypes]);

    // We want to allow groups, classes, etc. to be expanded or collapsed.
    const [_isGroupOpen, toggleGroup] = useToggleStateArray<string>([]);
    const [_isClassOpen, toggleClass] = useToggleStateArray<string>([]);

    const isGroupOpen = useCallback((id: string) => {
        // If the user has expanded this group, it is open.
        if (_isGroupOpen(id)) {
            return true;
        }

        //// If a class in this group matches the class search, the group should also appear open.
        if (classSearch) {
            const hasClass = !!items?.find(it => it._groupKey === id);
            if (hasClass) {
                return true;
            }
        }

        // If the user is searching for a child, and there is a match within this class for this group, the group should also appear open.
        if (cubSearch) {
            const hasCub = !!items?.filter(it => it._groupKey === id && !!it.cubSearchResults.length)?.length;
            if (hasCub) {
                return true;
            }
        }

        // If we get here, we have no reason to be open.
        return false;
    }, [_isGroupOpen, cubSearch, items, classSearch]);

    const isClassOpen = useCallback((id: string) => {
        // If the user has expanded this class, it is open.
        if (_isClassOpen(id)) {
            return true;
        }

        // If the user is searching for a child, and there is a match within this class, the class should also appear open.
        if (cubSearch) {
            const item = items?.find(it => it.id === id);
            if (item?.cubSearchResults?.length) {
                return true;
            }
        }

        // If we get here, we have no reason to be open.
        return false;
    }, [_isClassOpen, cubSearch, items]);

    // Get a list of all the groups we are going to show.
    const groups = useMemo(() => {
        const groups = new Set<string>();
        items?.forEach(item => groups.add(item._groupKey));
        return Array.from(groups);
    }, [items]);

    // Child health notes modal
    const [childForHealthNoteModal, setChildForHealthNoteModal] = useState<Child | undefined>();
    const [isMedicalInformationModalOpen, _toggleMedicalInformationModal] = useToggleState(false);
    const toggleMedicalInformationModal = useCallback((child: Child) => {
        setChildForHealthNoteModal(child);
        _toggleMedicalInformationModal();
    }, [_toggleMedicalInformationModal]);

    // Member notes modal
    const [memberForNotesModal, setMemberForNotesModal] = useState<MemberProfileWithStaffNotes | undefined>();
    const [isMemberNotesModalOpen, _toggleMemberNotesModal] = useToggleState(false);
    const toggleMemberNotesModal = useCallback((memberProfileWithNotes: MemberProfileWithStaffNotes) => {
        setMemberForNotesModal(memberProfileWithNotes);
        _toggleMemberNotesModal();
    }, [_toggleMemberNotesModal]);

    // Get lesson dates for class
    const getLessonDateForClass = useCallback((scheduledClass: ScheduledClass) => {
        const start = moment(currentTerm?.startDate);
        const end = moment(currentTerm?.endDate);
        const lessonDates: Array<string> = [];

        // If start does not match dayOfWeek, increment by days until it does.
        while (start.day() !== scheduledClass.dayOfWeek) {
            start.add(1, 'days');
        }

        // Set the current date
        const current = start.clone();

        // Check if we are in a holiday
        const termHolidaysList = termHolidays?.filter(it => it.termId === currentTerm?.id);

        // Iterate over the term, adding a date for each week.
        while (current < end) {
            // We don't want dates that fall outside of our term 
            if (current > end) {
                continue;
            }

            // If the lessonDate falls within a holiday then we will skip it, else add the date
            if (termHolidaysList?.find(it => it.startDate <= current.toISOString() && it.endDate > current.toISOString())) {
                continue;
            } else {
                lessonDates.push(current.toISOString());
            }

            // Increment the current date by 7 days
            current.add(7, 'days');
        }

        return lessonDates;
    }, [currentTerm, termHolidays]);

    // Refresh the content every 30 seconds -> It is important this screen is up to date.
    useEffect(() => {
        // Start an interval timer.
        const timer = setInterval(() => {
            refreshContent();
        }, 30 * 1000);

        // Cleanup function.
        return () => clearInterval(timer);
    }, [refreshContent]);

    // Render th e UI
    //
    return (
        <>
            <Banner fluid>
                <StickyToolbar>
                    <Row>
                        <Col xs={12} sm="auto">
                            <h1>
                                {t('classNavigator.title', 'Class navigator')}
                            </h1>
                            <h4>{terms?.find(it => it.id === filterState?.termId)?.name}</h4>
                        </Col>
                        <Col>
                            <Button color="primary" outline={!isFiltersVisible} onClick={() => toggleFiltersVisible()}>
                                <FontAwesomeIcon icon="filter" />
                                <> </>
                                {t('classNavigator.toggleFilters', 'Filter')}
                            </Button>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                    </Row>

                    <ConditionalFragment showIf={isFiltersVisible}>
                        <Card className="mb-2">
                            <CardHeader>
                                <h5>{t('classNavigator.filters.heading', 'Filter')}</h5>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col>
                                        <TermSelector selectedTermId={filterState.termId} setFilterState={({ termId: value }) => setFilterState({ termId: value })} restrictToTermsWithClasses={true} showPreviousTerms={true} />
                                    </Col>
                                    <Col>
                                        <Input type="select" value={filterState.classTypeId ?? ''} onChange={e => changeFilterState({ classTypeId: e.currentTarget.value, classStageId: '', classSubStageId: '', })}>
                                            <option value="">{t('classNavigator.filter.classType.all', 'All activities')}</option>
                                            {classTypes?.map(classType => (
                                                <option value={classType.id}>{classType.name}</option>
                                            ))}
                                        </Input>
                                    </Col>
                                    <ConditionalFragment showIf={!!filterState.classTypeId}>
                                        <Col>
                                            <Input type="select" value={filterState.classStageId ?? ''} onChange={e => changeFilterState({ classStageId: e.currentTarget.value, classSubStageId: '', })}>
                                                <option value="">{t('classNavigator.filter.classStages.all', '(All class types)')}</option>
                                                {
                                                    classStagesForFilter?.map(item => (
                                                        <option key={item.id} value={item.id}>{item.name}</option>
                                                    ))
                                                }
                                            </Input>
                                        </Col>
                                    </ConditionalFragment>
                                    <ConditionalFragment showIf={!!filterState.classStageId}>
                                        <Col>
                                            <Input type="select" value={filterState.classSubStageId ?? ''} onChange={e => changeFilterState({ classSubStageId: e.currentTarget.value })}>
                                                <option value="">{t('classNavigator.filter.classSubStages.all', '(All stages)')}</option>
                                                {
                                                    classSubStagesForFilter?.map(item => (
                                                        <option key={item.id} value={item.id}>{item.name}</option>
                                                    ))
                                                }
                                            </Input>
                                        </Col>
                                    </ConditionalFragment>
                                    <Col>
                                        <Input type="select" value={filterState.classLocationId ?? ''} onChange={e => changeFilterState({ classLocationId: e.currentTarget.value })}>
                                            <option value="">{t('classNavigator.filter.classLocation.all', 'All locations')}</option>
                                            {filteredClassLocations?.map(classLocation => (
                                                <option value={classLocation.id}>{classLocation.name}</option>
                                            ))}
                                        </Input>
                                    </Col>
                                </Row>
                                <Button color="link" onClick={resetFilters}>
                                    {t('classNavigator.filters.reset', 'Reset filters')}
                                </Button>
                            </CardBody>
                        </Card>
                    </ConditionalFragment>

                    <Row style={{ marginTop: '1rem' }}>
                        <Col>
                            <SearchInput value={classSearch} onChange={e => setClassSearch(e.currentTarget.value)} placeholder={'Class search'} />
                        </Col>

                        <Col>
                            <SearchInput value={cubSearch} onChange={e => setCubSearch(e.currentTarget.value)} placeholder={'Cub search'} />
                        </Col>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer fluid>
                <AlertOnErrors
                    errors={[
                        currentTermLoadErrors,
                        loadErrors
                    ]}
                />

                {/* Show each group, to do this we work through the group ids and show details from the first matching item */}
                <Table responsive striped className="class-navigator">
                    <thead>
                        <tr className="class-navigator-classes-headings">
                            <th>&nbsp;</th> {/* This is where the + or - will show */}
                            <th>
                                {t('classNavigator.activity.heading', 'Activity')}
                            </th>
                            <th>
                                {t('classNavigator.location.heading', 'Location')}
                            </th>
                            <th>
                                {t('classNavigator.day.heading', 'Day')}
                            </th>
                            <th>
                                {t('classNavigator.classes.heading', 'Classes')}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            groups.map(group => {
                                // Get the first group, we'll use this to show the details.
                                const firstItem = items?.find(item => item._groupKey === group);

                                // If we didn't have one then return null.
                                if (!firstItem) {
                                    return null;
                                }

                                // We will behave differently depending on if we are collapsed or open.
                                const isMyGroupOpen = isGroupOpen(group);

                                // Lets get all the classes in this group.
                                const myItems = items?.filter(item => item._groupKey === group);

                                // Return the group as a row to be displayed.
                                return (
                                    <Fragment key={group}>
                                        <tr>
                                            <td onClick={() => toggleGroup(group)}>
                                                <FontAwesomeIcon icon={isMyGroupOpen ? 'minus' : 'plus'} />
                                            </td>
                                            <td>
                                                {firstItem.classType?.name}
                                            </td>
                                            <td>
                                                {firstItem.classLocation?.name}
                                            </td>
                                            <td>
                                                {dayOfWeekDisplayName(firstItem.dayOfWeek, t)}
                                            </td>
                                            <td>
                                                {myItems.length}
                                            </td>
                                        </tr>
                                        {
                                            // If we are open, we want to show the details of the classes under ourselves.
                                            isMyGroupOpen ? (
                                                <Fragment>
                                                    <tr style={{ display: 'none' }}>
                                                        <td colSpan={5}>
                                                            {/* This exists just to keep the colouring consistant with the row above */}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            &nbsp;
                                                            {/* Blank space where the + goes */}
                                                        </td>
                                                        <td colSpan={4}>
                                                            <Card body className="class-navigator-classes-card">
                                                                <Table responsive striped>
                                                                    <thead>
                                                                        <tr className="class-navigator-classes-card-headings">
                                                                            <th>&nbsp;</th> {/* + or - */}
                                                                            <th>
                                                                                {t('classNavigator.time.heading', 'Time')}
                                                                            </th>
                                                                            <th>
                                                                                {t('classNavigator.classType.heading', 'Class type')}
                                                                            </th>
                                                                            <th>
                                                                                {t('classNavigator.stage.heading', 'Stage')}
                                                                            </th>
                                                                            <th>
                                                                                {t('classNavigator.cubs.heading', 'Cubs')}
                                                                            </th>
                                                                            <th>
                                                                                {t('classNavigator.cubs.spaces', 'Spaces')}
                                                                            </th>
                                                                            <th>
                                                                                {t('classNavigator.waitingList.spaces', 'Waiting list')}
                                                                            </th>
                                                                            <th>
                                                                                {t('classNavigator.register.heading', 'Register')}
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            myItems.map(item => {
                                                                                const allMyCubs = item.cubs;
                                                                                const myVisibleCubs = item.cubSearchResults;

                                                                                const isMyClassOpen = isClassOpen(item.id);

                                                                                const termClassDates = getLessonDateForClass(item);

                                                                                // Return the UI.
                                                                                return (
                                                                                    <Fragment key={item.id}>
                                                                                        <tr>
                                                                                            <td onClick={() => toggleClass(item.id)}>
                                                                                                <FontAwesomeIcon icon={isMyClassOpen ? 'minus' : 'plus'} />
                                                                                            </td>
                                                                                            <td>
                                                                                                {t('common.startTime', '{{startTimeHours, 00}}:{{startTimeMinutes, 00}}', { startTimeHours: item.startTimeHours, startTimeMinutes: item.startTimeMinutes })}
                                                                                            </td>
                                                                                            <td>
                                                                                                {item.classStage?.name}
                                                                                            </td>
                                                                                            <td>
                                                                                                {item.classSubStage?.name}
                                                                                            </td>
                                                                                            <td>
                                                                                                {myVisibleCubs.length !== allMyCubs.length ? `${myVisibleCubs.length} of ${allMyCubs.length} match search` : `${allMyCubs.length}`}
                                                                                            </td>
                                                                                            <td>
                                                                                                {item.maximumClassSize - (myVisibleCubs.length !== allMyCubs.length ? myVisibleCubs.length : allMyCubs.length)}
                                                                                            </td>
                                                                                            <td>
                                                                                                {item.waitingListCubs.length}
                                                                                                <> </>
                                                                                                <a className="class-navigator-classes-link" href={`/administration/children-management/waiting-lists/${item.id}`} target="_blank" rel="noreferrer">
                                                                                                    {t('common.view', 'View')}
                                                                                                </a>
                                                                                            </td>
                                                                                            <td>
                                                                                                <a className="class-navigator-classes-link" href={`/administration/children-management/register/${item.id}`} target="_blank" rel="noreferrer">
                                                                                                    {t('common.manage', 'Manage')}
                                                                                                </a>
                                                                                            </td>
                                                                                        </tr>
                                                                                        {
                                                                                            // If we are open, we want to show the details of the classes under ourselves.
                                                                                            isMyClassOpen ? (
                                                                                                <Fragment>
                                                                                                    <tr style={{ display: 'none' }}>
                                                                                                        <td colSpan={7}>
                                                                                                            {/* This exists just to keep the colouring consistant with the row abnove */}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            &nbsp;
                                                                                                            {/* Blank space where the + goes */}
                                                                                                        </td>
                                                                                                        <td colSpan={7}>
                                                                                                            <Card body className="class-navigator-registers">
                                                                                                                <Table responsive striped>
                                                                                                                    <thead>
                                                                                                                        <tr className="class-navigator-registers-headings">
                                                                                                                            <th>
                                                                                                                                {t('classNavigator.cubNumberHash.heading', '#')}
                                                                                                                            </th>
                                                                                                                            <th>
                                                                                                                                {t('classNavigator.bearCub.heading', 'bear cub')}
                                                                                                                            </th>
                                                                                                                            <th>
                                                                                                                                {t('classNavigator.age.heading', 'Age')}
                                                                                                                            </th>
                                                                                                                            <th>
                                                                                                                                {t('classNavigator.memberFirstName.heading', 'mama / papa bear')}
                                                                                                                            </th>
                                                                                                                            <th>
                                                                                                                                {t('classNavigator.phone.heading', 'Phone')}
                                                                                                                            </th>
                                                                                                                            <th>
                                                                                                                                {t('classNavigator.townEircode.heading', 'Town / Eircode')}
                                                                                                                            </th>
                                                                                                                            <th>
                                                                                                                                {t('classNavigator.medicalInformation.heading', 'MI')}
                                                                                                                            </th>
                                                                                                                            <th>
                                                                                                                                {t('classNavigator.note.heading', 'Note')}
                                                                                                                            </th>
                                                                                                                            {
                                                                                                                                Array.from({ length: item.lessonsPerTerm }, (_, i) => i + 1).map(lessonNumber => (
                                                                                                                                    <th>
                                                                                                                                        {lessonNumber}
                                                                                                                                    </th>
                                                                                                                                ))
                                                                                                                            }
                                                                                                                            <th>
                                                                                                                                {t('classNavigator.rate.heading', 'Rate')}
                                                                                                                            </th>
                                                                                                                        </tr>
                                                                                                                    </thead>
                                                                                                                    <tbody>
                                                                                                                        {
                                                                                                                            myVisibleCubs.map((cub, index) => {
                                                                                                                                const myAttendances = item.cubAttendances;
                                                                                                                                const currentLessonNumber = item.currentLessonNumber;

                                                                                                                                const calculateAttendanceRate = (cub: Child) => {
                                                                                                                                    const numberOfLessons = currentLessonNumber - 1;

                                                                                                                                    const attendances = myAttendances.filter(it => it.childId === cub.id && it.attended).length;

                                                                                                                                    if (numberOfLessons === 0 && attendances === 0) return 0;

                                                                                                                                    return numberOfLessons / 100 * attendances * 1000;
                                                                                                                                };


                                                                                                                                // Helper to let us know if we attended for a week.
                                                                                                                                const didAttendWeek = (week: number) => {
                                                                                                                                    const today = moment();

                                                                                                                                    const isFutureTerm = moment(terms.find(it => it.id === filterState.termId)?.startDate) > moment(today);

                                                                                                                                    // If the lessonDate is in the future return null
                                                                                                                                    if (moment(termClassDates[week - 1]) > today || isFutureTerm) {
                                                                                                                                        return null;
                                                                                                                                    }

                                                                                                                                    // Return the attendance
                                                                                                                                    return myAttendances.filter(item => item.childId === cub.id)?.find(it => moment(it.lessonDate) >= moment(termClassDates[week - 1]) && moment(it.lessonDate) < moment(termClassDates[week - 1]).add(1, 'day'))?.attended ?? false;
                                                                                                                                };

                                                                                                                                const ageOfCub = (dateOfBirth: string) => {
                                                                                                                                    let age = `${moment().diff(moment(dateOfBirth), 'years')} yrs`;

                                                                                                                                    if (age === `0 yrs`) {
                                                                                                                                        age = `${moment().diff(moment(dateOfBirth), 'months')} mnths`;
                                                                                                                                    }

                                                                                                                                    return age;
                                                                                                                                };

                                                                                                                                // Return the UI.
                                                                                                                                return (
                                                                                                                                    <Fragment key={cub.id}>
                                                                                                                                        <tr>
                                                                                                                                            <td>
                                                                                                                                                {index + 1}
                                                                                                                                            </td>
                                                                                                                                            <td>
                                                                                                                                                <a className="class-navigator-registers-link" href={`/administration/children-management/children/edit/${cub?.id}`} target="_blank" rel="noreferrer">
                                                                                                                                                    {cub.firstName}
                                                                                                                                                </a>
                                                                                                                                            </td>
                                                                                                                                            <td>
                                                                                                                                                {ageOfCub(cub.dateOfBirth)}
                                                                                                                                            </td>
                                                                                                                                            <td>
                                                                                                                                                <a className="class-navigator-registers-link" href={`/administration/children-management/members/edit/${cub.memberProfile?.id}`} target="_blank" rel="noreferrer">
                                                                                                                                                    {t('common.fullName', '{{firstName}} {{lastName}}', { firstName: cub.memberProfile?.firstName, lastName: cub.memberProfile?.lastName })}
                                                                                                                                                </a>
                                                                                                                                            </td>
                                                                                                                                            <td>
                                                                                                                                                {cub.memberProfile?.primaryPhone}
                                                                                                                                            </td>
                                                                                                                                            <td>
                                                                                                                                                {cub.memberProfile?.city} / {cub.memberProfile?.postcode}
                                                                                                                                            </td>
                                                                                                                                            <td id={`tooltip-healthNote-${cub.id}`} onClick={() => toggleMedicalInformationModal(cub)}>
                                                                                                                                                <ConditionalFragment showIf={!!cub.healthNoteHtml.length}>
                                                                                                                                                    <TooltipWrapper targetId={`tooltip-healthNote-${cub.id}`} icon="file-medical" color={iconColors('health-note')} content={''} clickHandler={() => { }}>
                                                                                                                                                        <HtmlDisplay html={cub.healthNoteHtml} />
                                                                                                                                                    </TooltipWrapper>
                                                                                                                                                </ConditionalFragment>
                                                                                                                                            </td>
                                                                                                                                            <td id={`tooltip-notes-${cub.id}`} onClick={() => toggleMemberNotesModal({ ...cub.memberProfile, notes: cub.memberNotes } as MemberProfileWithStaffNotes)}>
                                                                                                                                                <ConditionalFragment showIf={!!cub.memberNotes.length}>
                                                                                                                                                    <TooltipWrapper targetId={`tooltip-notes-${cub.id}`} icon="sticky-note" color={iconColors('staff-note')} content={''} clickHandler={() => { }}>
                                                                                                                                                        {cub.memberNotes?.map(note => <div><HtmlDisplay html={note.contentsHtml} /></div>)}
                                                                                                                                                    </TooltipWrapper>
                                                                                                                                                </ConditionalFragment>
                                                                                                                                            </td>
                                                                                                                                            {
                                                                                                                                                Array.from({ length: item.lessonsPerTerm }, (_, i) => i + 1).map(lessonNumber => (
                                                                                                                                                    <td>
                                                                                                                                                        {
                                                                                                                                                            didAttendWeek(lessonNumber) === null ? "" : didAttendWeek(lessonNumber) ? <FontAwesomeIcon icon='check' color="green" /> : <FontAwesomeIcon icon='xmark' color="red" />
                                                                                                                                                        }
                                                                                                                                                    </td>
                                                                                                                                                ))
                                                                                                                                            }
                                                                                                                                            <td>
                                                                                                                                                {calculateAttendanceRate(cub)}%
                                                                                                                                            </td>
                                                                                                                                        </tr>
                                                                                                                                    </Fragment>
                                                                                                                                );
                                                                                                                            })
                                                                                                                        }
                                                                                                                    </tbody>
                                                                                                                </Table>
                                                                                                            </Card>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </Fragment>
                                                                                            )
                                                                                                : null
                                                                                        }
                                                                                    </Fragment>

                                                                                );
                                                                            })
                                                                        }
                                                                    </tbody>
                                                                </Table>
                                                            </Card>
                                                        </td>
                                                    </tr>
                                                </Fragment>
                                            )
                                                : null
                                        }
                                    </Fragment>
                                );
                            })
                        }
                    </tbody>
                </Table>

                {/* Cub health note modal */}
                <StyledModal isOpen={isMedicalInformationModalOpen} toggle={() => _toggleMedicalInformationModal()} size="lg">
                    <ModalHeader toggle={() => _toggleMedicalInformationModal()}>
                        {t('common.fullName', '{{firstName}} {{lastName}}', { firstName: childForHealthNoteModal?.firstName, lastName: childForHealthNoteModal?.lastName })}
                    </ModalHeader>

                    <ModalBody>
                        <Label htmlFor="healthNotes">{t('classNavigator.healthNoteModal.label', 'Health note(s)')}</Label>
                        <HtmlDisplay html={childForHealthNoteModal?.healthNoteHtml ?? ''} />
                    </ModalBody>

                    <ModalFooter>
                        <Button type="button" color="primary" onClick={() => _toggleMedicalInformationModal()}>
                            {t('common.close', 'Close')}
                        </Button>
                    </ModalFooter>
                </StyledModal>

                {/* Member notes modal */}
                <StyledModal isOpen={isMemberNotesModalOpen} toggle={() => _toggleMemberNotesModal()} size="lg">
                    <ModalHeader toggle={() => _toggleMemberNotesModal()}>
                        {t('common.fullName', '{{firstName}} {{lastName}}', { firstName: memberForNotesModal?.firstName, lastName: memberForNotesModal?.lastName })}
                    </ModalHeader>

                    <ModalBody>
                        <Label htmlFor="memberNotes">{t('classNavigator.memberNotesModal.label', 'Note(s)')}</Label>
                        <ListGroup>
                            {
                                memberForNotesModal?.notes?.map(item => {
                                    const staffProfile = profiles?.find(it => it.userId === item.updatedByUserId);

                                    return <ListGroupItem key={item.id}>
                                        <HtmlDisplay html={item.contentsHtml ?? ''} />
                                        <Badge color="secondary">
                                            {t('classNavigator.memberNotesModal.updatedBy', 'Updated by ')}
                                            {t('common.fullName', '{{firstName}} {{lastName}}', { firstName: staffProfile?.firstName, lastName: staffProfile?.lastName })}
                                            {t('classNavigator.memberNotesModal.on', ' on ')}
                                            {t('common.dateTimeLong', '{{date, dddd, MMMM Do YYYY, HH:mm:ss}}', { date: moment(item.updatedDate) })}
                                        </Badge>
                                    </ListGroupItem>;
                                })
                            }
                        </ListGroup>
                    </ModalBody>

                    <ModalFooter>
                        <Button type="button" color="primary" onClick={() => _toggleMemberNotesModal()}>
                            {t('common.close', 'Close')}
                        </Button>
                    </ModalFooter>
                </StyledModal>
            </MainContainer>
        </>
    );
};