import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { useAsyncCallback } from "react-use-async-callback";
import { Waypoint } from "react-waypoint";
import { Alert, Button, Card, CardBody, CardHeader, Col, Input, Row, Spinner } from "reactstrap";
import { ButtonAsync } from "reactstrap-buttonasync";
import { useCancelChildAbsenceMutation } from "../../api/main/childAbsences/useCancelChildAbsenceMutation";
import { useChildAbsencesListViewModel } from "../../api/main/childAbsences/viewModels/useChildAbsencesListViewModel";
import { CancelChildAbsenceResult } from "../../api/main/generated/graphql";
import { dayOfWeekDisplayName } from "../../api/main/models/constants/DayOfWeek";
import { useCurrentTerm } from "../../api/main/terms/useCurrentTerm";
import { AlertOnErrors } from "../../shared/alertOnErrors";
import { useLocalStorage } from "../../shared/useLocalStorage";
import { useReplaceSearchParamsEffect, useSearchParams } from "../../shared/useURLSearchParams";
import { getScheduledClassSummary } from "../scheduledClass/getScheduledClassSummary";
import { ClassScheduleFilterState } from "../scheduledClass/schedule/ClassSchedule";
import { Banner } from "../shared/banner/Banner";
import { CardsOrTable } from "../shared/cardsOrTable/CardsOrTable";
import { LoadingIndicator } from "../shared/loadingIndicator/LoadingIndicator";
import { MainContainer } from "../shared/mainContainer/MainContainer";
import { NoResultsFound } from "../shared/noResultsFound/NoResultsFound";
import { SearchInput } from "../shared/searchInput/SearchInput";
import { StickyToolbar } from "../shared/stickyToolbar/StickyToolbar";
import { TermSelector } from "../shared/termSelector/TermSelector";

/**
 * List of ChildAbsences
 */
export const ChildAbsencesList = () => {
    const { t } = useTranslation();

    // Load data.
    const {
        data: {
            items: _allItems,
            classTypes,
            classLocations,
            classStages,
            classSubStages,
            terms,
            classLocationClassTypes,
            scheduledClasses,
            children,
            childAbsenceTypes,
            profiles
        },
        isLoading: isLoadingData, errors: loadingErrors, fetchMore, hasMore, refresh } = useChildAbsencesListViewModel({ pageSize: undefined, }); // Refine this to remove what isn't needed.

    // Load the current term
    const {
        data: {
            model: currentTerm
        }, isLoading: isLoadingCurrentTerm, errors: currentTermLoadErrors
    } = useCurrentTerm();
    const isLoading = isLoadingData || isLoadingCurrentTerm;

    // Combine all the loaded data so each item includes its related details.
    const allItems = useMemo(() => _allItems?.map(item => {
        const child = children?.find(it => it.id === item.childId);
        const childAbsenceType = childAbsenceTypes?.find(it => it.id === item.childAbsenceTypeId);

        const scheduledClass = scheduledClasses?.find(it => it.id === item.scheduledClassId);
        const classLocation = classLocations?.find(it => it.id === scheduledClass?.classLocationId);
        const classStage = classStages?.find(it => it.id === scheduledClass?.currentClassStageId);
        const classSubStage = classSubStages?.find(it => it.id === scheduledClass?.currentClassSubStageId);
        const member = profiles?.find(it => it.id === child?.memberId);
        const term = terms?.find(it => it.id === scheduledClass?.termId);
        const classType = classTypes?.find(it => it.id === classStage?.classTypeId);

        return {
            ...item,

            scheduledClass,
            child,
            childAbsenceType,
            classLocation,
            classStage,
            classSubStage,
            member,
            term,
            classType
        };
    }), [_allItems, scheduledClasses, children, childAbsenceTypes, classLocations, classStages, classSubStages, profiles, terms, classTypes]);

    // Search.
    const { search: searchParam } = useSearchParams();
    const [search, setSearch] = useState<string>(searchParam ?? '');

    // Keep the URL up to date with the search text.
    useReplaceSearchParamsEffect({ search: search });

    // Allow filtering (persisted to local storage between sessions).
    const [filterState, setFilterState] = useLocalStorage<ClassScheduleFilterState>('childAbsencesList.filterState', {});
    const changeFilterState = useCallback((changes: Partial<ClassScheduleFilterState>) => setFilterState(prevState => ({
        ...prevState,
        ...changes,
    })), [setFilterState]);

    // Filter the items that can be filtered by other filter choices.
    const classStagesForFilter = useMemo(() => classStages?.filter(item => item.classTypeId === filterState.classTypeId), [classStages, filterState]);
    const classSubStagesForFilter = useMemo(() => classSubStages?.filter(item => item.classStageId === filterState.classStageId), [classSubStages, filterState]);

    // Filter by the search.
    const items = useMemo(() => {
        let ret = (allItems ?? []);

        let lowerSearch = search.toLocaleLowerCase();
        if (lowerSearch) {
            // Just filtering by all string values that arent ids to start with. Most likely wont need them all for search.
            ret = ret.filter(item =>
                (item.classLocation?.name ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || (item.classStage?.name ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || (item.classSubStage?.name ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || dayOfWeekDisplayName(item?.scheduledClass?.dayOfWeek ?? 0, t).toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || (item.term?.name ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || (item.child?.firstName + ' ' + item.child?.lastName).toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || (item.member?.firstName + ' ' + item.member?.lastName).toLocaleLowerCase().indexOf(lowerSearch) >= 0
                || (item.childAbsenceType?.name ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
            );
        }

        if (filterState.classTypeId) {
            ret = ret?.filter(item => item?.scheduledClass?.classTypeId === filterState.classTypeId);
        }

        if (filterState.classStageId) {
            ret = ret?.filter(item => item?.scheduledClass?.currentClassStageId === filterState.classStageId);
        }

        if (filterState.classSubStageId) {
            ret = ret?.filter(item => item?.scheduledClass?.currentClassSubStageId === filterState.classSubStageId);
        }

        if (filterState.classLocationId) {
            ret = ret?.filter(item => item?.scheduledClass?.classLocationId === filterState.classLocationId);
        }

        if (filterState.termId) {
            ret = ret?.filter(item => item?.scheduledClass?.termId === filterState.termId);
        }

        if (filterState.dayOfWeek) {
            ret = ret?.filter(item => item?.scheduledClass?.dayOfWeek.toString() === filterState.dayOfWeek);
        }

        return ret;

    }, [allItems, search, t, filterState]);

    // Showing and hiding UI elements (persisted to local storage between sessions).
    const [isFiltersVisible, _setIsFiltersVisible] = useLocalStorage('classSchedule.isFiltersVisible', false);
    const toggleFiltersVisible = useCallback(() => _setIsFiltersVisible(prevState => !prevState), [_setIsFiltersVisible]);
    const resetFilters = useCallback(() => setFilterState({ termId: currentTerm?.id }), [setFilterState, currentTerm?.id]);

    // Filtering the ClassLocations -> If viewing a specific class type, only show the locations that have that class type.
    const filteredClassLocations = useMemo(() => {
        if (!classLocations || !classLocationClassTypes) {
            return;
        }

        // If we aren't filtering by class type, return all class locations.
        if (!filterState?.classTypeId) {
            return classLocations;
        }

        // Otherwise, return only the class locations that have the class type.
        return classLocations.filter(classLocation => {
            const matchingClassTypes = classLocationClassTypes.filter(classLocationClassType => {
                return classLocationClassType.classLocationId === classLocation.id && classLocationClassType.classTypeId === filterState.classTypeId;
            });

            return matchingClassTypes.length > 0;
        });
    }, [filterState, classLocations, classLocationClassTypes]);

    const [messageType, setMessageType] = useState<boolean | undefined | null>(false);
    const [message, setMessage] = useState<string | undefined | null>(undefined);

    // Cancel a child absence.
    const [cancelChildAbsence, { errors: cancelChildAbsenceErrors }] = useCancelChildAbsenceMutation();
    const [cancel, { isExecuting: isCancelling, errors: cancelErrors }] = useAsyncCallback(async (id: string) => {
        if (!id) return;

        const result: CancelChildAbsenceResult = await cancelChildAbsence(id);

        if (result) {
            setMessageType(result.success);
            setMessage(result.message);

            // Hide the message after a few seconds.
            setTimeout(() => setMessage(undefined), 5000);
        } else {
            //console.log('No result');
        }

        refresh();
    }, [cancelChildAbsence]);


    // Set the default filter state to the current term.
    useEffect(() => {
        if (!filterState.termId) {
            setFilterState({ termId: currentTerm?.id });
        }
    }, [filterState, currentTerm?.id, setFilterState]);

    // Render the UI
    //
    return (
        <>
            <Banner fluid>
                <StickyToolbar>
                    <Row>
                        <Col xs={12} sm="auto">
                            <h1>
                                {t('childAbsencesList.title', 'Child absences')}
                            </h1>
                        </Col>
                        <Col>
                            <Button color="primary" outline={!isFiltersVisible} onClick={() => toggleFiltersVisible()}>
                                <FontAwesomeIcon icon="filter" />
                                <> </>
                                {t('childAbsencesList.toggleFilters', 'Filter')}
                            </Button>
                        </Col>
                        <ConditionalFragment showIf={isLoading}>
                            <Col xs="auto">
                                <LoadingIndicator size="sm" />
                            </Col>
                        </ConditionalFragment>
                    </Row>

                    {/* Filters */}
                    <ConditionalFragment showIf={isFiltersVisible}>
                        <Card className="mb-2">
                            <CardHeader>
                                <h5>{t('childAbsencesList.filters.heading', 'Filter')}</h5>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col xs="auto">
                                        <TermSelector selectedTermId={filterState.termId} setFilterState={({ termId: value }) => setFilterState({ termId: value })} restrictToTermsWithClasses={true} showPreviousTerms={true} />
                                    </Col>
                                    <Col xs="auto">
                                        <Input type="select" value={filterState.dayOfWeek ?? ''} onChange={e => changeFilterState({ dayOfWeek: e.currentTarget.value, })}>
                                            <option value="">{t('childAbsencesList.filter.dayOfWeek.all', '(All days)')}</option>
                                            <option value="0">{t('childAbsencesList.filter.dayOfWeek.sunday', 'Sunday')}</option>
                                            <option value="1">{t('childAbsencesList.filter.dayOfWeek.monday', 'Monday')}</option>
                                            <option value="2">{t('childAbsencesList.filter.dayOfWeek.tuesday', 'Tuesday')}</option>
                                            <option value="3">{t('childAbsencesList.filter.dayOfWeek.wednesday', 'Wednesday')}</option>
                                            <option value="4">{t('childAbsencesList.filter.dayOfWeek.thursday', 'Thursday')}</option>
                                            <option value="5">{t('childAbsencesList.filter.dayOfWeek.friday', 'Friday')}</option>
                                            <option value="6">{t('childAbsencesList.filter.dayOfWeek.saturday', 'Saturday')}</option>
                                        </Input>
                                    </Col>
                                    <Col>
                                        <Input type="select" value={filterState.classTypeId ?? ''} onChange={e => changeFilterState({ classTypeId: e.currentTarget.value, classStageId: '', classSubStageId: '', })}>
                                            <option value="">{t('childAbsencesList.filter.classTypes.all', '(All activities)')}</option>
                                            {
                                                classTypes?.map(item => (
                                                    <option key={item.id} value={item.id}>{item.name}</option>
                                                ))
                                            }
                                        </Input>
                                    </Col>
                                    <ConditionalFragment showIf={!!filterState.classTypeId}>
                                        <Col>
                                            <Input type="select" value={filterState.classStageId ?? ''} onChange={e => changeFilterState({ classStageId: e.currentTarget.value, classSubStageId: '', })}>
                                                <option value="">{t('childAbsencesList.filter.classStages.all', '(All class types)')}</option>
                                                {
                                                    classStagesForFilter?.map(item => (
                                                        <option key={item.id} value={item.id}>{item.name}</option>
                                                    ))
                                                }
                                            </Input>
                                        </Col>
                                    </ConditionalFragment>
                                    <ConditionalFragment showIf={!!filterState.classStageId}>
                                        <Col>
                                            <Input type="select" value={filterState.classSubStageId ?? ''} onChange={e => changeFilterState({ classSubStageId: e.currentTarget.value })}>
                                                <option value="">{t('childAbsencesList.filter.classSubStages.all', '(All stages)')}</option>
                                                {
                                                    classSubStagesForFilter?.map(item => (
                                                        <option key={item.id} value={item.id}>{item.name}</option>
                                                    ))
                                                }
                                            </Input>
                                        </Col>
                                    </ConditionalFragment>
                                    <Col>
                                        <Input type="select" value={filterState.classLocationId ?? ''} onChange={e => changeFilterState({ classLocationId: e.currentTarget.value })}>
                                            <option value="">{t('childAbsencesList.filter.classLocations.all', '(All locations)')}</option>
                                            {
                                                filteredClassLocations?.map(item => (
                                                    <option key={item.id} value={item.id}>{item.name}</option>
                                                ))
                                            }
                                        </Input>
                                    </Col>
                                </Row>
                                <Button color="link" onClick={resetFilters}>
                                    {t('childAbsencesList.filters.reset', 'Reset filters')}
                                </Button>
                            </CardBody>
                        </Card>
                    </ConditionalFragment>

                    <Row>
                        <Col>
                            <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)} />
                        </Col>
                    </Row>
                </StickyToolbar>
            </Banner>

            <MainContainer fluid>
                <AlertOnErrors errors={[
                    loadingErrors,
                    currentTermLoadErrors,
                    cancelChildAbsenceErrors, cancelErrors
                ]} />

                <ConditionalFragment showIf={!!message}>
                    <Alert color={messageType === true ? 'success' : 'danger'}>
                        {message}
                    </Alert>
                </ConditionalFragment>

                <CardsOrTable
                    viewMode={"table"}
                    items={items}
                    tableHeadings={[
                        null, /* Handles the card specific function for which we don't want a table column. */
                        t('childAbsencesList.cub.heading', 'Cub'),
                        t('childAbsencesList.member.heading', 'Member'),
                        t('childAbsencesList.class.heading', 'Class'),
                        t('childAbsencesList.type.heading', 'Type'),
                        t('childAbsencesList.absenceDate.heading', 'Absence date'),
                        t('childAbsencesList.submittedDate.heading', 'Submitted date'),
                        t('childAbsencesList.receivedCredit.heading', 'Received credit?'),
                    ]}
                    columns={[
                        // Cub
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return t('common.fullName', '{{firstName}} {{lastName}}', { firstName: item.child?.firstName, lastName: item.child?.lastName });
                        },

                        // Member
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return t('common.fullName', '{{firstName}} {{lastName}}', { firstName: item.member?.firstName, lastName: item.member?.lastName });
                        },

                        // Class
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return getScheduledClassSummary(item.scheduledClass, { classLocation: item.classLocation, classStage: item.classStage, classSubStage: item.classSubStage });
                        },

                        // Type
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return item.childAbsenceType?.name;
                        },

                        // Absence date
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(item.startDate) });
                        },

                        // Submitted date
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return t('common.date', '{{date, DD/MM/YYYY}}', { date: moment(item.submittedDate) });
                        },

                        // Received credit?
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return item.receivedCourtesyClassCredit ? <FontAwesomeIcon icon="check" color="green" /> : <FontAwesomeIcon icon="xmark" color="red" />;
                        },

                        // Cancel
                        (item, view) => {
                            if (view !== 'table') {
                                return null;
                            }

                            return (
                                <ButtonAsync color="danger" outline isExecuting={isCancelling} onClick={() => cancel(item.id)}
                                    executingChildren={<><Spinner size="sm" /> {t('common.cancelling', 'Cancelling...')}</>}>
                                    {t('common.cancel', 'Cancel')}
                                </ButtonAsync>
                            );
                        },
                    ]}
                />

                <ConditionalFragment showIf={isLoading && !items?.length}>
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>
                <ConditionalFragment showIf={!isLoading && !items?.length}>
                    <NoResultsFound search={search} />
                </ConditionalFragment>
                <ConditionalFragment showIf={!isLoading && hasMore()}>
                    <Waypoint key={items?.length ?? 0} onEnter={fetchMore} />
                    <LoadingIndicator fullWidth />
                </ConditionalFragment>
            </MainContainer>
        </>
    );
};